export default [
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
  {
    'id': 1,
    'name': "SB-36 18' White",
    'cost': '$200.00',
    'stock': '12',
    'hours': '0.5',
    'description': "18ft White Top Decking",
    'client_name':'Kegelbell'
  },
  {
    'id': 2,
    'name': `TRIM 12' x 6" Cameo`,
    'cost': '$50.00',
    'stock': '23',
    'hours': '0.2',
    'description': "12ft Cameo Trim",
    'client_name':'Just Fred'
  },
]
